import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import EMLogo from "../components/EMLogo"
import heroImage from "../images/2V2A9743.jpg"

import youAreAwesomeGif from "../images/youareawesome.gif"

const heroSectionStyle = {
  background: 'url(' + heroImage + ')',
}

const GetLovePage = () => (
  <Layout title="Empower + Rise: Get Love">
    <section
      style={heroSectionStyle}
      className="white overflow-hidden relative cover bg-center"
    >
      <div className="mt4 flex flex-column flex-row-ns justify-around items-center">
        <Link to="/">
          <EMLogo style={{ fill: "#fff", width: "160px" }} />
        </Link>
      </div>

      <div className="tc mv6">
        <h2 className="f-subheadline-ns f2 lh-title mb3">We Love You</h2>
      </div>

      <div className="pv3-ns">
        <div className="w-100 flex justify-center">
          <img className="awesome-gif" src={youAreAwesomeGif} />
        </div>
      </div>
    </section>

    <section className="black mh3 mv6-ns mv5 bg-white pa3 ph5-ns tc">
      <div className="f5 f3-ns flex flex-column space-between lh-title brandon-light lh-copy w-50-ns center">
        <div className="mb5">
          In fact, we love you so much we cooked up a special love note for you.
          That’s what we do here at Empower + Rise. We deliver love notes around
          the globe daily. <br /> Wanna see it?
        </div>
        <div className="f4 tracked brandon-bold rust">
          POP IN YOUR INFO BELOW <br className="dn dib-ns" /> AND WE’LL
          IMMEDIATELY SEND YOU SOME LOVE EVERY DAY.
        </div>

        <form
          className="tc brandon-light mt4  infusion-form"
          acceptCharset="UTF-8"
          action="https://iv153.infusionsoft.com/app/form/process/7bc5bffde428819322b772338477eadc"
          id="inf_form_7bc5bffde428819322b772338477eadc"
          method="POST"
        >
          <input
            name="inf_form_xid"
            type="hidden"
            value="7bc5bffde428819322b772338477eadc"
          />
          <input
            name="inf_form_name"
            type="hidden"
            value="E+R Love Notes &#a;Sign Up Form"
          />
          <input
            name="infusionsoft_version"
            type="hidden"
            value="1.70.0.123227"
          />
          <div className="infusion-field">
            <label hidden htmlFor="inf_field_FirstName">
              First Name
            </label>
            <input
              className="f5 f4-ns bn bg-gray input-reset ba b--black-20 mb2 pa2 db w-100-m w-100 w-50-l center"
              id="inf_field_FirstName"
              name="inf_field_FirstName"
              placeholder="First Name"
              type="text"
            />
          </div>
          <div className="infusion-field">
            <label hidden htmlFor="inf_field_LastName">
              Last Name
            </label>
            <input
              className="f5 f4-ns bn bg-gray input-reset ba b--black-20 mb2 pa2 db w-100-m w-100 w-50-l center"
              id="inf_field_LastName"
              name="inf_field_LastName"
              placeholder="Last Name"
              type="text"
            />
          </div>
          <div className="infusion-field">
            <label hidden htmlFor="inf_field_Email">
              Email
            </label>
            <input
              className="f5 f4-ns bn bg-gray input-reset ba b--black-20 mb2 pa2 db w-100-m w-100 w-50-l center"
              id="inf_field_Email"
              name="inf_field_Email"
              placeholder="Email"
              type="text"
            />
          </div>
          <div className="infusion-field">
            <label hidden htmlfor="inf_custom_24">
              2+4= *
            </label>
            <input
              className="f5 f4-ns bn bg-gray input-reset ba b--black-20 mb2 pa2 db w-100-m w-100 w-50-l center"
              id="inf_custom_24"
              name="inf_custom_24"
              placeholder="2+4= *"
              type="text"
            />
          </div>

          <input name="inf_custom_FavoritePlant1" type="hidden" value="null" />
          <div className="infusion-submit">
            <button
              className="pointer f7 w-50-l w-100-m w-100 link ph4 pv3 mt2 mb4 dib white bg-black brandon-bold tracked infusion-recaptcha"
              id="recaptcha_7bc5bffde428819322b772338477eadc"
              type="submit"
            >
              LOVE ME UP!
            </button>
          </div>
        </form>
        <script
          type="text/javascript"
          src="https://iv153.infusionsoft.app/app/webTracking/getTrackingCode"
        ></script>
        <script
          type="text/javascript"
          src="https://iv153.infusionsoft.com/resources/external/recaptcha/production/recaptcha.js?b=1.70.0.89421"
        ></script>
        <script
          src="https://www.google.com/recaptcha/api.js?onload=onloadInfusionRecaptchaCallback&render=explicit"
          async="async"
          defer="defer"
        ></script>
        <script
          type="text/javascript"
          src="https://iv153.infusionsoft.com/app/timezone/timezoneInputJs?xid=7bc5bffde428819322b772338477eadc"
        ></script>
      </div>
    </section>
  </Layout>
)

export default GetLovePage
